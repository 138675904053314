body {
	//caret-color: rgba(0, 0, 0, 0); // rimuove il blink del cursore

	@apply font-opensansregular text-ribola-dove-gray overflow-x-hidden;
}

*:hover {
	-webkit-tap-highlight-color: transparent;
}

picture {
	@apply w-full flex;
}

.customSelect select {
	background-image: none !important;
}

img,
svg,
video,
iframe {
	display: block;
}

img.minContent {
	height: min-content;
}

img.fadeInImg {
	@apply opacity-0 transition duration-500 ease-in-out;
}

img.fadeInImg.loaded {
	@apply opacity-100;
}

input.noArrows::-webkit-outer-spin-button,
input.noArrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input.noArrows[type='number'] {
	-moz-appearance: textfield;
}

.container {
	min-width: 320px;
}

.gridArea11 {
	grid-area: 1 / -1;
}

ol {
	/* margin-left: 1rem; */
	list-style: decimal;
}

ul {
	/* margin-left: 1rem; */
	list-style: disc;
}
