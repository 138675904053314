@layer base {
	@include font-face(
		PoppinsRegular,
		'../webfonts/Poppins-Regular',
		400,
		normal,
		woff2
	);
	@include font-face(
		PoppinsSemiBold,
		'../webfonts/Poppins-SemiBold',
		600,
		normal,
		woff2
	);
	@include font-face(
		OpenSansRegular,
		'../webfonts/OpenSans-Regular',
		400,
		normal,
		woff2
	);
	@include font-face(
		OpenSansSemiBold,
		'../webfonts/OpenSans-SemiBold',
		600,
		normal,
		woff2
	);
	@include font-face(
		OpenSansBold,
		'../webfonts/OpenSans-Bold',
		700,
		bold,
		woff2
	);
}
