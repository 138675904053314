/*!
 * Font Awesome Pro 6.0.0-alpha2 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
// Font Awesome core compile (Web Fonts-based)
// -------------------------

@import 'variables';
@import 'mixins';
@import 'core';
@import 'sizing';
@import 'fixed-width';
@import 'list';
@import 'bordered-pulled';
@import 'animated';
@import 'rotated-flipped';
@import 'stacked';
@import 'icons';
@import 'screen-reader';

// styles (gli altri sono thin regular light duotone)
@import "regular";
@import "solid";
@import "light";
@import "brands";
