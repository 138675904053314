/* REACT MULTI CAROUSEL GENERIC ARROW STYLES */

.react-multiple-carousel__arrow {
	@apply bg-ribola-astral transition-all z-10;
}

.react-multiple-carousel__arrow:focus {
	@apply outline-none transform scale-125;
}

.react-multiple-carousel__arrow::before {
	font-family: $fa-style-family;
	@apply text-xl text-white font-bold;
}

.react-multiple-carousel__arrow--right {
	@apply right-2;
}

.react-multiple-carousel__arrow--left {
	@apply left-2;
}

.react-multiple-carousel__arrow--right::before {
	content: fa-content($fa-var-chevron-right);
}

.react-multiple-carousel__arrow--left::before {
	content: fa-content($fa-var-chevron-left);
}

/* END REACT MULTI CAROUSEL GENERIC ARROW STYLES */

.navTopItem {
	@apply flex items-end border-b-4 border-white transition font-poppinsregular;
}

.navTopItem:hover,
.navTopItem.selected {
	@apply border-ribola-amber;
}

.socialIcon {
	@apply rounded-full h-8 w-8 mx-1 xl:mx-2 flex items-center justify-center transition bg-ribola-astral text-white;
}

.socialIcon:hover {
	@apply bg-ribola-amber transform scale-110 rotate-12;
}

.customSelect {
	@apply relative;
}

.customSelect select {
	@apply appearance-none bg-white border border-ribola-dove-gray rounded-md focus:outline-none cursor-pointer font-poppinsregular pl-4 pr-10 py-2;
}

.customSelect::after {
	font-family: $fa-style-family;
	content: fa-content($fa-var-chevron-right);
	@apply transition-all absolute right-4 top-2;
}

.customSelect:hover::after {
	@apply transform rotate-90;
}

.topSearch {
	@apply relative;
}

.topSearch input {
	@apply font-poppinsregular transition focus:outline-none focus:placeholder-opacity-50;
}

.topSearch input:focus {
	box-shadow: none;
}

@media screen and (max-width: 1024px) {
	.topSearch input:-webkit-autofill,
	.topSearch input:-webkit-autofill:hover,
	.topSearch input:-webkit-autofill:focus,
	.topSearch input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color: white;
		border-color: white !important;
	}
}

.topSearchSubmit::after {
	font-family: $fa-style-family;
	content: fa-content($fa-var-search);
	@apply transition-all absolute right-2 sm:right-4 text-2xl pointer-events-none;
}

.amberButton {
	@apply bg-ribola-amber hover:bg-ribola-web-orange transition text-white rounded-md focus:outline-none cursor-pointer font-poppinsregular px-5 pt-2 pb-1;
}

.amberButtonBig {
	@apply bg-ribola-amber text-white rounded-md inline-flex self-end justify-center items-center cursor-pointer;
}

.buttonBorderedTransparent {
	@apply font-poppinssemibold border-4 rounded-md flex justify-center items-center cursor-pointer pt-2 pb-1;
}

.buttonBordered {
	@apply font-poppinssemibold rounded-md flex justify-center items-center cursor-pointer pt-3 pb-2 px-5;
}

.buttonCongressBlue {
	@apply button button--hovef px-5 pt-3 pb-2 bg-ribola-congress-blue text-white relative block focus:outline-none rounded-lg text-center font-poppinsregular overflow-hidden transform-gpu;
}

.whiteButtonBordered {
	@apply border-4 border-white text-white rounded-md inline-flex self-end justify-center items-center cursor-pointer;
}

.cartButton {
	@apply cursor-pointer font-poppinsregular;
}

/* HEADER HEIGHTS */
.headerHomepageContainer {
	height: auto;
}

@screen sm {
	.headerHomepageContainer {
		height: 312px;
	}
}

@screen lg {
	.headerHomepageContainer {
		height: 375px;
	}
}

@screen xl {
	.headerHomepageContainer {
		height: 469px;
	}
}

@screen 2xl {
	.headerHomepageContainer {
		height: 563px;
	}
}

@screen 3xl {
	.headerHomepageContainer {
		height: 594px;
	}
}

@screen 4xl {
	.headerHomepageContainer {
		height: 642px;
	}
}

.headerHomepageDots {
	width: fit-content;
	margin: 0 auto;
	@apply rounded-lg py-1.5 mb-3 bg-black bg-opacity-80;
}

.headerHomepageDots button {
	@apply border-none w-4 h-4 mx-3 bg-white transition;
}

.headerHomepageDots li {
	@apply flex items-center;
}

.headerHomepageDots button:focus {
	@apply outline-none;
}

.headerHomepageDots .react-multi-carousel-dot--active button {
	@apply bg-opacity-100 w-6 h-6;
}

.headerHomepageDots .react-multi-carousel-dot button:hover:active {
	@apply bg-ribola-web-orange;
}

.headerHomepageDots .react-multi-carousel-dot--active button {
	@apply bg-ribola-web-orange;
}

.imageHeader > span {
	display: inline !important;
}

.titoloBlocco {
	@apply font-poppinssemibold;
}

.sottoTitoloBlocco {
	@apply font-poppinsregular;
}

.containerCategorie {
	overflow-x: scroll;
	display: grid;
	grid-auto-flow: column;
	gap: 0;
	scroll-snap-type: x mandatory;
	-webkit-overflow-scrolling: touch;
	grid-template-rows: repeat(4, minmax(0, 1fr));
}

.categoryItem {
	min-width: 84vw;
	max-width: fit-content;
	scroll-snap-align: start;
	@apply w-max mx-3 my-2 sm:m-3 xl:m-0;
}

@media screen and (max-width: 480px) {
	.categoryItem {
		min-width: 70vw;
		scroll-snap-align: center;
	}
}

.categoryItemSub {
	@apply w-full flex items-center relative border-3 xl:border-b xl:border-t-0 xl:border-l-0 xl:border-r-0 xl:border-gray-300 cursor-pointer transition duration-300;
}

.menuLeft .categoryItemSub {
	@apply border-b border-t-0 border-l-0 border-r-0;
}

.categoryItemSub a {
	@apply flex items-center;
}

.categoryItemSub span {
	@apply items-center;
}

.categoryItemSub img {
	@apply transition duration-300;
}

.categoryItemSub.selected {
	@apply cursor-default;
}

.categoryItemSub:hover img,
.categoryItemSub.selected img {
	@apply filter brightness-0 invert;
}

.categoryItemSub i {
	@apply absolute right-2/4 opacity-0 transition-all duration-500;
}

.categoryItemSub:hover i,
.categoryItemSub.selected i {
	@apply right-4 opacity-100;
}

.categoryItemName {
	@apply font-poppinsregular;
}

@media screen and (max-width: 340px) {
	.categoryItem span {
		@apply text-xs;
	}
}

@media screen and (max-width: 480px) {
	.elencoProdotti .productBoxItem {
		max-width: 20rem;
	}
}

@screen xs {
	.categoryItem {
		max-width: none;
		min-width: 0;
		width: 300px;
	}
}

@screen sm {
	.categoryItem {
		width: 285px;
	}
}

@screen md {
	.categoryItem {
		width: 296px;
	}
}

@screen lg {
	.categoryItem {
		width: 305px;
	}
}

@screen xl {
	.containerCategorie {
		@apply w-full grid-cols-60 grid-flow-row overflow-auto;
	}
	.categoryItem {
		@apply max-w-none w-auto col-span-12;
	}
}

.productBoxItem {
	@apply relative bg-white filter drop-shadow-md transition-all duration-500 transform-gpu hover:scale-105 sm:hover:scale-110 p-4;
}

.productBoxItem .titolo {
	@apply font-opensansbold;
}

.productBoxItem .codice {
	@apply text-ribola-tundora flex mb-1;
}

.productBoxItem .dettagliButton {
	@apply w-3/5 transition-all duration-300 transform-gpu;
}

.productBoxItem.small .dettagliButton {
	@apply w-8/12;
}

.productBoxItem:hover .dettagliButton {
	@apply w-full;
}

.immagineAlternativa {
	position: absolute;
	left: 0;
	top: 0;
}

.productBoxItem:hover .immagineAlternativa {
	@apply translate-x-0 translate-y-0;
}

.lastSlideCategoryHome {
	@apply relative filter drop-shadow-md transition-all duration-500 transform-gpu hover:scale-105 sm:hover:scale-110 p-4;
}

.lastSlideCategoryHome .buttonBorderedTransparent {
	@apply w-3/5 transition-all duration-300 transform-gpu;
}

.lastSlideCategoryHome:hover .buttonBorderedTransparent {
	@apply w-full;
}

.lastSlideCategoryHome img {
	@apply transition-all duration-300 transform-gpu;
}

.lastSlideCategoryHome:hover img {
	@apply transform scale-125;
}

.lastSlideCategoryHome span.titolo {
	@apply transition-all duration-200 transform-gpu;
}

.lastSlideCategoryHome:hover span.titolo {
	@apply -translate-y-8;
}

.iconaTag {
	@apply text-xs text-white rounded-sm;
}

.iconaTag.interna {
	@apply mb-0;
}

.prezzoScontato {
	@apply line-through text-xl text-ribola-dust-gray font-opensansbold;
}

.prezzoProdotto {
	@apply font-opensansbold mt-2;
}

.prezzoProdotto small {
	@apply font-opensansregular ml-2;
}

.prezzoProdotto.nomt {
	@apply mt-0;
}

.scontoFasciaArancione {
	@apply absolute -top-3.5 -left-3.5 flex flex-col justify-center items-center;
}

.scontoFasciaArancione.small {
	@apply -top-2 -left-2;
}

.scontoFasciaInterna {
	@apply absolute text-white transform -rotate-45 flex flex-col justify-center items-center top-5 left-3.5 text-xl;
}

.scontoFasciaInterna.small {
	@apply text-base top-3 left-2;
}

.scontoFasciaArancione.fascia_arancione_en .scontoFasciaInterna {
	@apply left-0;
}

.scontoFasciaArancione.fascia_arancione_fr .scontoFasciaInterna {
	@apply -left-1;
}

.scontoFasciaInterna .titoloFasciaInterna {
	@apply text-4xl font-opensansbold;
}

.scontoFasciaInterna.small .titoloFasciaInterna {
	@apply text-2xl leading-none;
}

.dettagliButton {
	@apply relative bg-ribola-congress-blue-variant text-white font-opensansbold inline-flex rounded-sm transition-all;
}

.dettagliButton::after {
	font-family: $fa-style-family;
	content: fa-content($fa-var-chevron-right);
	@apply transition-all absolute right-4 text-sm;
}

.dettagliButton.richiediInfo {
	@apply bg-ribola-amber text-ribola-heavy-metal w-11/12;
}

/* INSPIRATIONS HOME */

@media screen and (max-width: 1024px) {
	.containerFluido {
		@apply max-w-none;
	}
	.elencoInspiration {
		overflow-x: scroll;
		overflow-y: hidden;
	}
	.inspirationItem {
		min-width: 90vw;
	}
}

.elencoInspiration {
	scroll-snap-type: x mandatory;
}

.inspirationProductItem {
	@apply absolute;
}

.inspirationItem {
	scroll-snap-align: center;
}

.inspirationItem > span {
	display: inline !important;
	@apply w-full;
}

.inspirationMarker {
	@apply absolute flex items-center justify-center rounded-full duration-300 border-8 border-white bg-gray-200 text-black cursor-pointer;
}

.inspirationMarker:hover {
	@apply border-12;
}

.boxProdottoInspirationHover {
	@apply flex transition-all duration-300 absolute w-full border rounded-2xl bg-white overflow-hidden;
}

.magazineHomeItem {
	@apply relative bg-white border border-ribola-alto filter drop-shadow-md transition-all duration-500 transform-gpu hover:scale-110 p-5;
}

.magazineHomeItem .dettagliButton {
	@apply w-6/12 transition-all duration-300 transform-gpu;
}

.magazineHomeItem:hover .dettagliButton {
	@apply w-full;
}

.elencoBlocchiMagazine ul {
	@apply m-4;
}

.footerTitle {
	@apply block text-ribola-congress-blue font-opensanssemibold text-lg mb-4;
}

.slideshowProductDetail .react-multiple-carousel__arrow {
	min-width: 55px;
	min-height: 55px;
	border: 1px solid #ddd;
	@apply rounded-none bottom-0 right-0 bg-transparent;
}

.slideshowProductDetail .react-multiple-carousel__arrow::before {
	@apply text-ribola-alto;
}

.slideshowProductDetail .react-multiple-carousel__arrow--left {
	@apply left-0;
}

.inputNumberStyled input {
	@apply w-full bg-transparent text-ribola-dove-gray border border-ribola-dove-gray border-opacity-20 focus:border-opacity-100 focus:outline-none placeholder-gray-500 px-2 sm:px-3 2xl:px-4 py-1;
}

.slideshowAccessoriKit .react-multiple-carousel__arrow {
	min-width: auto;
	min-height: auto;
	@apply bg-transparent;
}

.slideshowAccessoriKit .react-multiple-carousel__arrow::before {
	@apply text-ribola-quicksand text-base font-light;
}

.slideshowAccessoriKit .react-multiple-carousel__arrow--left {
	@apply left-0;
}

.slideshowAccessoriKit .react-multiple-carousel__arrow--right {
	@apply right-0;
}

.spanServerSide span:nth-of-type(1) {
	display: inline !important;
}

.briciole .selected {
	@apply text-gray-300;
}

.lazy-load-image-background.opacity:not(.lazy-load-image-loaded) {
	opacity: 0;
	display: inline !important;
}

.lazy-load-image-background.opacity.lazy-load-image-loaded {
	opacity: 1;
	transition: opacity 0.3s;
}

.__react_component_tooltip.type-dark {
	@apply rounded-md bg-ribola-heavy-metal text-white;
}

.addedToCartRibola .swal2-popup,
.addedToCartRibola .confirmButtonRibola,
.addedToCartRibola .cancelButtonRibola {
	@apply rounded-none;
}

.swal2-actions {
	@apply flex-row-reverse;
}

.videoTube iframe {
	width: 80%;
	height: inherit;
}

// hamburger menu
.menuHamburger {
	padding: 0 !important;
}
.menuHamburger .swal2-popup {
	border-radius: 0 !important;
	width: 23em;
	height: auto;
	min-height: 100vh;
	display: flex !important;
	flex-direction: column;
}
.menuHamburger .swal2-close {
	align-self: flex-end !important;
}
.menuHamburger .swal2-close:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	box-shadow: none !important;
}
.menuHamburger .htmlHamburger {
	height: 100%;
}
.modal-open {
	position: fixed;
}

// modal kit
.modalKit .swal2-popup {
	border-radius: 0 !important;
	width: 90%;
	height: auto;
	margin: 0 auto;
	padding: 3rem 6rem;
	display: flex !important;
	flex-direction: column;
	align-items: flex-start;
	align-self: center !important;
}
.modalKit .swal2-close {
	align-self: flex-end !important;
}
.modalKit .swal2-close:focus {
	outline: 2px solid transparent;
	outline-offset: 2px;
	box-shadow: none !important;
}
.modalKit .htmlModalKit {
	height: 100%;
	width: 100%;
	padding: 0;
}
@media screen and (max-width: 1280px) {
	.tableModalKit {
		min-width: 900px;
	}
}
@media screen and (max-width: 1024px) {
	.modalKit .swal2-popup {
		width: 100%;
		min-height: 100vh;
		padding: 1rem 2rem;
		align-self: flex-start !important;
	}
}

.subMenuLogged {
	display: none;
}

.showProfileSubMenu:hover .subMenuLogged {
	display: flex;
}

.fdt_merchant150x100-1_widget_merchant {
	background: transparent !important;
	align-items: flex-start !important;
}
