.header-top {
  @apply z-20 border-b border-gray-500;
}

.header-top:not(.interno) {
  @apply relative;
}

.header-middle {
  @apply relative flex-grow z-10 text-white;
}

.header-nav {
  @apply h-full flex items-end;
}

.header-img {
  @apply absolute z-0 inset-0;
}

@responsive {
  .headermh {
    min-height: 1000px;
  }
}

.header-img img {
  width: max-content;
  width: -webkit-fill-available;

  @apply max-w-none;
}
