/* Hover Effect */
.button.button--hovef {
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
}
.button--hovef::before,
.button--hovef::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
	bottom: 100%;
	left: 0;
	z-index: -1;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--hovef:hover::before,
.button--hovef:hover::after {
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
}
.button--hovef:hover::after {
	-webkit-transition-delay: 0.175s;
	transition-delay: 0.175s;
}
.button--hovef::before {
	background: #a0aec0;
}
// hover ribola-congress blue
.buttonCongressBlue.button--hovef::after {
	background: #0866b5;
}
