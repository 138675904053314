.react-select-container {
	@apply w-full sm:w-11/12;
}

.react-select-container .react-select__control {
	@apply border border-ribola-dove-gray border-opacity-100 rounded-none mt-3 py-2 pl-6 pr-0 sm:text-lg 2xl:text-base;
}

.react-select-container .react-select__control .react-select__value-container {
	@apply p-0 text-ribola-dove-gray;
}

.react-select-container
	.react-select__control
	.react-select__value-container
	.react-select__input {
	@apply ring-transparent;
}
