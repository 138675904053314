#richiedi-info {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#richiedi-info span.testo {
  font-size: 0.875rem;
}
