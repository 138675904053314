.hamburgerLines input {
	display: block;
	width: 100%;
	height: 40px;
	position: absolute;
	top: -5px;
	left: 0;
	opacity: 0;
	z-index: 2;
	-webkit-touch-callout: none;
	cursor: pointer;
}

.hamburgerLines span {
	@apply transition-all;
}

.hamburgerLines span:first-child {
	transform-origin: 0% 0%;
}

.hamburgerLines span:nth-last-child(1) {
	transform-origin: 0% 100%;
}

.hamburgerLines input:checked ~ span {
	opacity: 1;
	transform: rotate(45deg) translate(3px, -4px);
	background: white;
}

.hamburgerLines input:checked ~ span:nth-last-child(2) {
	opacity: 0;
	transform: rotate(0deg) scale(0.2, 0.2);
}

.hamburgerLines input:checked ~ span:nth-last-child(1) {
	transform: rotate(-45deg) translate(4px, 6px);
}

.hamburgerLines.reset input:checked ~ span {
	opacity: 1;
	transform: none;
}
