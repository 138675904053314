.StripeElement,
.StripeElementIdeal {
	display: block;
	margin: 10px 0 20px 0;
	max-width: 500px;
	padding: 10px 14px;
	box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
		rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
	border-radius: 4px;
	background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
	box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
		rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
	-webkit-transition: all 150ms ease;
	transition: all 150ms ease;
}

.StripeElement.loading {
	height: 41.6px;
	opacity: 0.6;
}

.StripeElementIdeal {
	padding: 0;
}
