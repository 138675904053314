.swal2-container.menu-mobile {
	@apply flex-col justify-start p-0;
}

.swal2-container.swal2-backdrop-show.menu-mobile,
.swal2-container.swal2-backdrop-show.menu-mobile .swal2-popup {
	background-color: #012e3b !important;
	width: 100%;
	justify-content: flex-start;
}

.swal2-container.menu-mobile .swal2-popup {
	@apply flex-col justify-start;
}

.swal2-container.swal2-backdrop-show.menu-mobile .swal2-popup .swal2-content {
	padding: 3rem 0;
}

.testataMobile .logo-mobile {
	max-width: 200px;
}

.testataMobile a::after {
	display: none;
}

.elencoVociMenuMobile nav {
	@apply flex flex-col;
}

.elencoVociMenuMobile nav ul li {
	flex-basis: 100%;

	@apply relative flex flex-col justify-start items-start p-0 m-0;
}

.elencoVociMenuMobile nav ul li::after {
	font-family: $fa-style-family;
	content: fa-content($fa-var-chevron-right);
	position: absolute;
	right: calc(1%);
	top: 10px;
	color: white;

	@apply transition-all;
}

.elencoVociMenuMobile nav ul li a,
.elencoVociMenuMobile nav ul li span,
.menu-sub-row {
	width: 100%;
	text-align: left;
	cursor: pointer;
	padding: 0.5rem 0;
	color: white;
	font-size: 0.875rem;
}

.elencoVociMenuMobile nav ul li.selected::after {
	@apply transform rotate-90;
}

.nav-header-bottom ul.subnavMobile {
	padding: 0;
	margin: 0;
	visibility: hidden;
	height: 0;

	@apply transition-all;
}

.nav-header-bottom ul.subnavMobile.selected {
	visibility: visible;
	height: auto;
	padding: 0.5rem 1rem;
	margin: 0.5rem 0 2rem 0;
}

.elencoVociMenuMobile nav ul li.spinnerMobile::after {
	display: none !important;
}

.menuCategorieMobile {
	border-radius: 10px;

	@apply bg-ribola-astral;
}

.titoloCatalogo {
	margin-bottom: 0 !important;
}

.titoloCatalogo::after {
	display: none !important;
}

.menu-sub-row {
	cursor: pointer;
}

.nav-header-bottom ul.subSubNavMobile {
	padding: 0 2rem;
}

.swal2-close:hover {
	transform: none;
	background: 0 0;
	color: #294484;
}

.swal2-close:focus {
	outline: none;
}
